function initNavbar() {
  // Navigation bar

  var $navbar = $('.navbar');

  $(document).scroll(function () {
    if ($(document).scrollTop() === 0) {
      $navbar.removeClass('above-all');
    } else {
      $navbar.addClass('above-all');
    }
  });


  // Offset canvas navigation

  var $wrapper = $('body');
  var $toggle = $('.nav-toggle');

  $toggle.click(function () {
    if ($wrapper.hasClass('offset')) {
      $toggle.removeClass('active');
      $wrapper.removeClass('offset');
    } else {
      $toggle.addClass('active');
      $wrapper.addClass('offset');
    }
  });

  $(document).click(function (e) {
    if ($wrapper.hasClass('offset') && $(e.target).closest('.offset-sidebar, .offset-bar').length == 0) {
      e.preventDefault();
      $toggle.removeClass('active');
      $wrapper.removeClass('offset');
    }
  });
}


function initTyped() {
  var phrases = [];
  $('.typed-strings li').each(function () {
    phrases.push(this.innerHTML);
  });
  $('.typed').typed({
    strings: phrases,
    backDelay: 3000,
    // shuffle: true,
    loop: true
  });
}


$(function () {
  initNavbar();
  initTyped();
});


// News
$(document).ready(function() {
  if (document.documentElement.id === 'news') {
    $.ajaxSetup({ cache: true });
    $.getScript('//connect.facebook.net/uk_UA/sdk.js', function() {
      FB.init({
        appId: '1803471736550811',
        version: 'v2.7'
      });
      // FB.getLoginStatus(function(response) {
      //   console.log(response);
      // });

      let $newsContainer = $('.news-feed');
      FB.api("/1566956696864877/feed", function (response) {
        if (response.error) {
          $newsContainer.html('<div class="container"><p><strong>Помилка доступу</strong></p></div>')
        }
        if (response && !response.error) {
          let news = '';
          for (let item of response.data) {
            console.log(item);
            if (!item.hasOwnProperty('message')) continue;

            news += '<div class="news-article section"><div class="container">' + item.message + '</div></div>';
          }
          $newsContainer.html(news);
        }
      }, {
        access_token: '1803471736550811|l3ssl2_m_FcpZ-0K-nG7SmgBBMc'
      });
    });
  }
});
